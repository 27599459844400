//bark-bin-screws
.grid-container-bark-bin-screws {
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  grid-template-rows: auto 1fr auto;
  grid-gap: 10px;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.bars-container-bark-bin-screws {
  grid-column: 2 / span 1;
  grid-row: 2 / span 1;
  overflow-x: auto;
}

.bars-bark-bin-screws {
  display: inline-block;
  width: 20px;
  height: 75px;
  margin: 5px;
  border: 1px solid black;
  text-align: center;
  line-height: 75px;
}

.number-of-screw {
  margin-top: 5px;
  text-align: center;
  font-weight: bold;
}

.top-message-bark-bin-screws {
  grid-column: 1 / span 3;
  grid-row: 1 / span 1;
  text-align: center;
}

.left-message-bark-bin-screws {
  grid-column: 1 / span 1;
  grid-row: 2 / span 1;
  text-align: center;
  transform: rotate(270deg);
  transform-origin: center;
}

.right-message-bark-bin-screws {
  grid-column: 3 / span 1;
  grid-row: 2 / span 1;
  text-align: center;
  transform: rotate(90deg);
  transform-origin: center;
}

.bottom-message-bark-bin-screws {
  grid-column: 1 / span 3;
  grid-row: 3 / span 1;
  text-align: center;
}
//legend
.legend-container {
  display: flex;
  align-items: center;
  margin-top: 15px;
  margin-bottom: 10px;
}
.legend-container-title {
  font-weight: bold;
  margin-right: 10px;
}
.legend-item {
  display: flex;
  align-items: center;
  margin-right: 10px;
}
.circle-legend {
  width: 12px;
  height: 12px;
  border: 1px solid black;
  margin-right: 5px;
}
.legend-item span {
  font-size: 14px;
}

// dust-collector-layout

.grid-container-dust-collector-layout {
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  grid-template-rows: auto 1fr auto;
  grid-gap: 10px;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.row-dust-collector-layout {
  display: flex;
  margin-top: 5px;
  width: 100%;
}

.grid-item-dust-collector-layout {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 16px;
  height: 16px;
  margin-left: 5px;
}

.circle-legend-dust-collector {
  width: 12px;
  height: 12px;
  border: 1px solid black;
  margin-right: 5px;
  border-radius: 50%;
}

.circle-dust-collector-layout {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: white;
  text-align: center;
  line-height: 12px;
  border-width: 1px;
  border-style: solid;
  border-color: black;
}

//Clockface Alignment Entry

.center-content-layout-alignment-entry {
  margin-top: 2px;
  margin-bottom: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.grid-container-alignment-entry {
  display: grid;
  grid-template-columns: auto auto;
  gap: 5px;
}

.image-container-alignment-entry {
  grid-column: 1;
}

.text-container-alignment-entry {
  grid-column: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: left;
}

.text-container-alignment-entry p {
  margin: 0;
  font-weight: bold;
}

.values-alignment-entry {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.values-alignment-entry p {
  margin-left: 3px;
  margin-right: 5px;
  font-size: 13px;
}

.small-input-alignment-entry {
  width: 84px;
  height: 15px;
  font-size: 12px;
  margin-right: 4px;
}

.small-input-alignment-entry-values {
  width: 84px;
  height: 15px;
  font-size: 12px;
  margin-left: 5px;
}

.text-container-alignment-entry p.dimensions-label {
  margin: 0;
  margin-bottom: 15px;
  text-align: left;
}

//Clockface

.circle-clockface {
  position: relative;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-color: white;
  border: 2px solid black;
  margin-top: 50px;
  margin-bottom: 50px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
}

.input-container-clockface {
  width: 70%;
  text-align: center;
}

.input-clockface {
  height: 20px;
  font-size: 12px;
  text-align: center;
}

.top-clockface {
  position: absolute;
  top: -45px;
}

.right-clockface {
  position: absolute;
  top: 50%;
  right: -60px;
  transform: translateY(-50%) translateX(50%);
}

.bottom-clockface {
  position: absolute;
  bottom: -45px;
}

.left-clockface {
  position: absolute;
  top: 50%;
  left: -60px;
  transform: translateY(-50%) translateX(-50%);
}

.triangle-up,
.triangle-down,
.triangle-left,
.triangle-right {
  width: 0;
  height: 0;
  border-style: solid;
  position: absolute;
}

.triangle-up {
  border-width: 0 8px 10px 8px;
  border-color: transparent transparent black transparent;
  bottom: 30px;
  left: 50%;
  transform: translateX(-50%);
}

.triangle-down {
  border-width: 10px 8px 0 8px;
  border-color: black transparent transparent transparent;
  top: 30px;
  left: 50%;
  transform: translateX(-50%);
}

.triangle-right {
  border-width: 8px 0 8px 10px;
  border-color: transparent transparent transparent black;
  top: 50%;
  left: 80px;
  transform: translateY(-50%);
}

.triangle-left {
  border-width: 8px 10px 8px 0;
  border-color: transparent black transparent transparent;
  top: 50%;
  right: 80px;
  transform: translateY(-50%);
}
