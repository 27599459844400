.card {
  box-shadow: $card-shadow;
  margin-bottom: ($spacer * 1.5);
}

a.card {
  cursor: pointer;
  text-decoration: none;
}

.card-header {
  background: transparent;
}

.card-body {
  padding-top: ($spacer * 0.75);
}

.card-title {
  color: $body-color;
  font-size: $font-size-base;
  line-height: 1.5;
}

.card-subtitle {
  font-weight: $font-weight-normal;
}

.card-table {
  margin-bottom: 0;
}

.card-table tr td:first-child,
.card-table tr th:first-child {
  padding-left: $card-spacer-x;
}

.card-table tr td:last-child,
.card-table tr th:last-child {
  padding-right: $card-spacer-x;
}

.card-img,
.card-img-top,
.card-img-bottom {
  @include img-fluid;

  @media all and (-ms-high-contrast: none) {
    height: 100%;
  }
}

.card-actions {
  a {
    color: $body-color;
    text-decoration: none;
  }

  svg {
    width: 16px;
    height: 16px;
  }

  .dropdown {
    line-height: 1.4;
  }
}

.card-img-hover {
  transition: all 0.1s ease-in-out;

  &:hover {
    transform: scale(1.035);
  }
}
